import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroHeader } from "../components/HeroHeader"
import ContentTest from "../components/ContentTest"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { FAQ } from "components/FAQ"
import LandingPage from "../components/LandingPage/LandingPage"
import { InternalHeader } from "../components/InternalHeader"

const Landing = ({ pageContext }) => {
  const {
    featuredImage,
    title,
    content,
    pageSettings,
    legAncestors,
    previewSQ,
  } = pageContext


  return (
    <Layout featuredImage={featuredImage}  pageContext={pageContext} preview={previewSQ}>
      <SEO title={title} />
      <InternalHeader featuredImage={featuredImage} title={title} />
      <Breadcrumbs title={title} data={legAncestors} />
      <LandingPage content={content} />
    </Layout>
  )
}

export default Landing
