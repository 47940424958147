import styled from "styled-components"
import { MainColumn } from "components/MainColumn"
import { device } from "breakpoints"

export const Container = styled(MainColumn)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 0;
  margin-bottom: 70px;

  @media ${device.desktop} {
    padding-top: 0;
    padding-bottom: 80px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 18px;
    @media ${device.desktop} {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
`

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1036px;
  border-top: 1px solid #979797;

  :last-of-type {
    border-bottom: 1px solid #979797;
  }
`

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 18px 0;
  transition: 0.1s ease-out;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;

:hover {
  p {
    font-weight:700;
  }
}
  @media ${device.desktop} {
    padding: 21px 0;
  }
  p {
    font-size: 16px;
    margin-right: 40px;
    max-width: 100%;
    color: ${props => props.theme.darkGreen};
    font-weight: 400;
    @media ${device.desktop} {
      font-size: 22px;
    }
  }

  svg {
    flex-shrink:0;
    height: ${props => (props.isOpen ? "16px" : "18px;")};
    width: ${props => (props.isOpen ? "16px" : "18px;")};

    margin: 0;
    vertical-align: middle;
    /* transform: ${props => (props.isOpen ? "rotateX(180deg)" : null)}; */

    @media ${device.desktop} {
      height: ${props => (props.isOpen ? "17px" : "19px;")};
      width: ${props => (props.isOpen ? "17px" : "19px;")};
    }

    path {
      fill: ${props => props.theme.darkGreen};
    }
  }
`

export const Answer = styled.div`
  background-color: white;
  display: ${props => (props.isOpen ? "block" : "none")};
  color: #003057;

  @media ${device.tablet} {
    padding-right: 30px;
  }

  @media ${device.desktop} {
    padding-right: 70px;
  }

  @media ${device.large} {
    padding-right: 100px;
  }
  p {
    font-size: 16px;
    color: black;
    padding: 0;
    margin-bottom: 20px;

    @media ${device.desktop} {
      font-size: 18px;
      margin-bottom: 8px;
    }

    a {
      /* font-weight: 700; */
      text-decoration: underline;
      color: ${props => props.theme.navy};

      :hover {
        color: ${props => props.theme.blue};
        font-weight: 500;
      }
    }
  }

  p.read-less {
    color: ${props => props.theme.darkGreen};
    cursor: pointer;
    background-position: 0 85%;
    font-weight: 300;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 45px;

    @media ${device.desktop} {
      /* margin-top: 45px; */
      margin-bottom: 50px;
    }

    :hover {
      color: ${props => props.theme.blue};
      font-weight: 500;
    }
  }

  span {
    font-weight: 0;
    font-family: "brandon_grotesque";
  }
`

export const IconWrapper = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  flex-grow: 0;

  @media ${device.desktop} {
    width: 25px;
    height: 25px;
  }
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  top: 50%;

  transition: 0.3s ease-out;
  background: ${props => props.theme.blue};
  position: absolute;
  transform: ${props =>
    props.isOpen
      ? "translate3d(0, -50%, 0) rotate(45deg)"
      : "translate3d(0, -50%, 0) rotate(0deg)"};
`

export const SecondLine = styled(FirstLine)`
  transform: ${props =>
    props.isOpen
      ? "translate3d(0, -50%, 0) rotate(-45deg)"
      : "translate3d(0, -50%, 0) rotate(90deg)"};
  display: block;
`
