import React, { Component } from "react"
import { Container, QuestionsContainer } from "./style.js"
import Question from "./question.js"
import { useStaticQuery, graphql } from "gatsby"

const FAQ = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          fAQS {
            nodes {
              title
              faq {
                answer
              }
            }
          }
        }
      }
    `
  )

  const faqs = data.wpgraphql.fAQS.nodes

  return (
    <Container maxWidth={835}>
      <h3>FAQs</h3>
      <QuestionsContainer>
        {faqs.map((faq, index) => {
          return (
            <Question
              question={faq.title}
              key={index}
              answer={faq.faq.answer}
            />
          )
        })}
      </QuestionsContainer>
    </Container>
  )
}

export default FAQ
