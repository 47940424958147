import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ContentTest from "../ContentTest"
import { ThreeTeaserRow } from "components/Teaser"
import { MainColumn } from "components/MainColumn"
import { LargeHomeTeaser } from "../LargeHomeTeaser"
// import { GreenLink } from "components/QuickLinksModule/style"
import { ReactComponent as Arrow } from "images/icons/arrow-right.svg"
import { LandingPageContainer, GreenLink } from "./style"

const LandingPage = ({ content }) => {
  console.log(content)
  const initialData = useStaticQuery(graphql`
    {
      wpgraphql {
        posts(first: 7) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            title
            link
            excerpt
            date
            featuredImage {
              sourceUrl
              srcSet
              mediaDetails {
                sizes {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)
  const initialPosts = initialData.wpgraphql.posts.nodes

  let firstPost = initialPosts.slice(0, 1)
  const [posts, setPosts] = useState(initialPosts.slice(1))
  const [pageInfo, setPageInfo] = useState(initialData.wpgraphql.posts.pageInfo)

  const loadMore = () => {
    const cursor = pageInfo.endCursor

    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
        {
          posts(first: 6, after: "${cursor}") {
            pageInfo {
              endCursor
              hasNextPage
            }
           nodes {
            title
            link
            excerpt
            date
            featuredImage {
              sourceUrl
              srcSet
              mediaDetails {
                sizes {
                  height
                  width
                }
              }
            }
          }
          }
        }
        
    `,
      }),
    })
      .then(res => res.json())
      .then(res => {
        console.log(res.data)

        setPosts([...posts, ...res.data.posts.nodes])
        setPageInfo(res.data.posts.pageInfo)
      })
  }

  return (
    <LandingPageContainer>
      {content && (
        // <MainColumn hideOverflow>
        <ContentTest content={content} />
        // </MainColumn>
      )}
      <MainColumn>
        <LargeHomeTeaser data={firstPost[0]} />
      </MainColumn>
      <ThreeTeaserRow data={posts} />

      {pageInfo.hasNextPage && (
        <GreenLink className="greenLink" onClick={() => loadMore()}>
          Load More <Arrow />
        </GreenLink>
      )}
    </LandingPageContainer>
  )
}

export default LandingPage
