import React, { Component } from "react"
import { QuestionWrapper, Answer, DropDownWrapper } from "./style.js"
import { ReactComponent as Cross } from "images/icons/cross.svg"
import { ReactComponent as Arrow } from "images/icons/arrow-right.svg"

class Question extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    const currentStatus = this.state.isOpen
    this.setState(
      {
        isOpen: !currentStatus,
      },
      () => console.log(this.state.isOpen)
    )
  }
  render() {
    return (
      <DropDownWrapper>
        <QuestionWrapper
          isOpen={this.state.isOpen}
          onClick={() => this.handleClick()}
        >
          <p> {this.props.question}</p>
          {this.state.isOpen ? <Cross /> : <Arrow />}
        </QuestionWrapper>
        <Answer isOpen={this.state.isOpen}>
          <div dangerouslySetInnerHTML={{ __html: this.props.answer }} />

          <p onClick={() => this.handleClick()} className="read-less underline">
            Read less
          </p>
        </Answer>
      </DropDownWrapper>
    )
  }
}

export default Question
