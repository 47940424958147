import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const LandingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 90px;

  @media ${device.desktop} {
    margin-top: 70px;
    margin-bottom: 100px;
  }

  p.landing {
    padding-bottom: 55px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: -10px;
    :before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 1px;
      background: #d5d5d5;
    }
    @media ${device.desktop} {
      padding-bottom: 70px;
      margin-bottom: 0px;
    }
  }
`
export const GreenLink = styled.button`
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  align-self: flex-start;
  color: white;
  background: ${props => props.theme.green};
  padding: 0 27px;
  border-radius: 24.75px;
  font-weight: 500;
  transition: 180ms;
  margin: 0 auto;
  margin-top: 70px;
  border: none;

  @media ${device.desktop} {
    font-size: 18px;
    padding: 0 30px;
    height: 55px;
    border-radius: 27.5px;
    margin-top: 100px;
  }

  :hover {
    opacity: 0.75;
  }

  svg {
    width: 17px;
    height: 16px;
    margin-left: 5px;

    @media ${device.desktop} {
      width: 18px;
      height: 17px;
      margin-left: 9px;
    }
  }
`
