import React from "react"
import { Content } from "../components/Content"
import { Staff } from "../components/Teaser"
import { MainColumn } from "components/MainColumn"

const ContentTest = ({ content }) => {
  return (
    <MainColumn>
      <Content content={content}/>
      {/* <p>
          Auburn Primary School has a range of programs to support eque porro
          quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
          adipisci veli.
        </p>

        <Staff />

        <figure className="wp-block-image alignfull">
          <img />
        </figure>

        <h2>2021 Enrollment</h2>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem apeadadr, eaque ipsa
          saquae ab illo inventore veritatis et quasi architecto beatae
          vitaasdass sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
          sit aspernatur aut odit aut fugitaas, sed ads quia consequuntur
          magninesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
          sit amet, consectetur, adipisci veli.
        </p>

        <div className="wp-block-button">
          <a href="" className="wp-block-button__link">
            Default State
          </a>
        </div>

        <div className="wp-block-button">
          <a href="" className="wp-block-button__link">
            Default State
          </a>
        </div>

        <h3>Transferring</h3>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem apeadadr, eaque ipsa
          saquae ab illo inventore veritatis et quasi architecto beatae
          vitaasdass sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
          sit aspernatur aut odit aut fugitaas, sed ads quia consequuntur
          magninesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
          sit amet, consectetur, adipisci veli.
        </p>
        <figure className="wp-block-image">
          <img />
        </figure>

        <h4>Enrollment Policy</h4>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem apeadadr, eaque ipsa
          saquae ab illo inventore veritatis et quasi architecto beatae
          vitaasdass sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
          sit aspernatur aut odit aut fugitaas, sed ads quia consequuntur
          magninesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
          sit amet, consectetur, adipisci veli.
        </p>

        <blockquote>
          <p>
            Auburn Primary School set et perspiciatisolu undaea omnis
            istolunatus error sit volup sed doolu esadadius tempor incidirror
            sit volup seolud do esadiudta.
          </p>
          <cite>
            <strong>Carol O'Neill</strong> - Current parent
          </cite>
        </blockquote>
      </Content> */}
    </MainColumn>
  )
}

export default ContentTest
